<template>
  <nut-navbar @on-click-back="backClick" title="批量上传"></nut-navbar>
  <div class="batchUpload">
    <div class="batchUpload__download">
      <label class="batchUpload__download__key">下载文件模板</label>
      <label class="batchUpload__download__btn">
        <nut-button
          color="#1fa4fc"
          type="primary"
          class="buttonDownload"
          @click="downloadClick()"
          >下载</nut-button
        >
      </label>
    </div>
    <div class="batchUpload__upload">
      <div class="batchUpload__upload__key">选择上传文件</div>
      <div class="batchUpload__upload__file">
        <nut-uploader
          :url="`/api/goods/admin/uploadGoodsTemplate`"
          maximum="999"
          :maximize="1024 * 500"
          @change="post"
          @success="handleSuccess"
          @oversize="onOversize"
          @progress="onProgress"
          :headers="headers"
          :with-credentials="true"
        >
          <nut-button type="primary" color="#1fa4fc" class="buttonUpload"
            >上传文件</nut-button
          >
        </nut-uploader>
      </div>
    </div>
    <nut-progress
      :percentage="progressPercentage"
      stroke-color="linear-gradient(270deg, rgba(18,126,255,1) 0%,rgba(32,147,255,1) 32.815625%,rgba(13,242,204,1) 100%)"
      :status="progressPercentage == 100 ? '' : 'active'"
    >
    </nut-progress>
  </div>
  <div v-if="msg" class="error">
    <div class="error__errorMsg">
      {{ msg }}
    </div>
    <div class="error__errorText" v-if="showErr" @click="downloadError">
      {{ errText }}
    </div>
  </div>
  <Docker :currentIndex="3" dockerName="My" />
</template>

<script setup>
import Docker from '@/components/Docker'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store'
import { Notify } from '@nutui/nutui'

const router = useRouter()
const msg = ref('')
const errUrl = ref('')
const errname = ref('')
const errText = ref('')
const showErr = ref(false)
const headers = reactive({
  'Access-Control-Allow-Origin': '*',
  Authorization: store.getters.token
})
// 进度条
const progressPercentage = ref(0)
const onProgress = ({ event, options, percentage }) => {
  progressPercentage.value = percentage
}
// 下载模板
const downloadClick = () => {
  const link = document.createElement('a')
  link.download = 'smallUploadGoodsTemplate.xlsx'
  link.href = '/data/smallUploadGoodsTemplate.xlsx'
  link.click()
  link.remove()
}
// 下载错误数据的Excel
const downloadError = () => {
  const link = document.createElement('a')
  link.download = errname.value
  link.href = errUrl.value
  link.click()
  link.remove()
}
// 上传文件
const handleSuccess = async (res) => {
  showErr.value = false
  const resTxt = res.responseText
  const resJson = JSON.parse(resTxt)
  if (resJson.code !== 200) {
    if (resJson.code === 201) {
      showErr.value = true
      errText.value = '请点此下载文件查看错误信息'
      errUrl.value = resJson.data.src
      errname.value = errUrl.value.toString().split('/').reverse()[0]
    }
    res.fileItem.status = 'error'
    res.fileItem.message = resJson.message
  }
  // 显示错误信息和下载链接
  msg.value = '*' + resJson.message
}
// 文件超过500KB弹出提示
const onOversize = async (files) => {
  Notify.warn('文件大小不能超过 500 kb', {
    duration: 3000
  })
}
// 回退事件逻辑
const backClick = () => {
  router.push('/my')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/customizedNutui.scss';
.batchUpload {
  position: relative;
  margin: 0 0.16rem;
  display: flex;
  flex-direction: column;
  padding: 0.12rem 0;
  &__download {
    margin: 0.1rem 0.16rem;
    padding: 0.06rem 0;
    &__key {
      justify-content: center;
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.16rem;
      color: $content-fontcolor;
      padding-top: 0.1rem;
      margin-right: 0.1rem;
    }
    &__btn {
      width: 150px;
    }
  }
  &__upload {
    margin: 0.1rem 0.16rem;
    padding: 0.06rem 0;
    &__key {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.16rem;
      color: $content-fontcolor;
      padding-top: 0.08rem;
      margin-right: 0.1rem;
      float: left;
    }
  }
}
.buttonDownload {
  width: 90px;
}
.buttonUpload {
  width: 120px;
}
.error {
  margin: 0.2rem 0.32rem 0.1rem 0.32rem;
  &__errorMsg {
    padding: 0.06rem 0;
    color: red;
    font-style: italic;
  }
  &__errorText {
    padding: 0.06rem;
    color: red;
  }
  &__errorUrl {
    display: flex;
    padding: 0.06rem;
    word-wrap: break-word;
  }
}
</style>
